export default [
  {
    url: '/',
    name: "Dashboard",
    icon: "HomeIcon",
    slug: "dashboard"
  },
  {
    url: null,
    name: "Orders",
    icon: "CalendarIcon",
    submenu: [
      {
        url: '/orders?status=pending',
        name: "Pending",
        slug: "orders",
      },
      {
        url: '/orders?status=delivered',
        name: "Delivered",
        slug: "orders",
      },
      {
        url: '/orders?status=revision',
        name: "Revision",
        slug: "orders",
      },
      {
        url: '/orders?status=completed',
        name: "Completed",
        slug: "orders",
      },
      {
        url: '/orders?status=refunded',
        name: "Refunded",
        slug: "orders",
      },
    ]
  },
  {
    url: "/chat",
    name: "Chat",
    slug: "chat",
    icon: "MessageSquareIcon",
  },
  {
    url: "/account",
    name: "Settings",
    slug: "account",
    icon: "SettingsIcon",
  },

]

