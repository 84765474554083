<template>
  <!-- NOTIFICATIONS -->
  <vs-dropdown vs-custom-content class="cursor-pointer">
    <feather-icon icon="BellIcon" class="cursor-pointer mt-1 h-6 w-6 sm:mr-6 mr-2" :badge="unreadNotifications.length" />

    <vs-dropdown-menu class="notification-dropdown dropdown-custom vx-navbar-dropdown">

      <div class="notification-top text-center p-1 bg-primary text-white">
      </div>

      <VuePerfectScrollbar ref="mainSidebarPs" class="scroll-area--nofications-dropdown p-0 mb-10" :settings="settings" :key="$vs.rtl">
        <notification-list
         v-if="displayNotifs && displayNotifs.length"
         :list="displayNotifs"
         />
      </VuePerfectScrollbar>

      <div class="
        checkout-footer
        fixed
        bottom-0
        rounded-b-lg
        text-primary
        w-full
        p-2
        font-semibold
        text-center
        border
        border-b-0
        border-l-0
        border-r-0
        border-solid
        d-theme-border-grey-light
        cursor-pointer"
        @click="$router.push('/notifications').catch(() => {})"
        >
        <span>View All Notifications</span>
      </div>
    </vs-dropdown-menu>
  </vs-dropdown>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import NotificationList  from './NotificationList.vue'

export default {
  components: {
    VuePerfectScrollbar,
    NotificationList
  },
  data() {
    return {
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: .60,
      },
    }
  },
  computed: {
    activeUser() {
      return this.$store.state.AppActiveUser
    },
    pageData(){
      return this.$store.state.notification.pageData
    },
    unreadNotifications() {
      return this.pageData.data.filter((notif) => (notif.is_read == 0))
    },
    readNotifications() {
      return this.pageData.data.filter((notif) => (notif.is_read == 1))
    },
    displayNotifs(){
      return (this.unreadNotifications.length > 1) ?
        [...this.unreadNotifications.slice(0,10), ...this.readNotifications.slice(0,10)] :
        this.pageData.data.slice(0,10)
    }
  },
  methods: {
    markAsRead(notifId){
      this.$store.dispatch("notification/markNotifAsRead", notifId)
    },
  },
  created(){
    this.$store.dispatch("notification/fetchNotifications")
  },
  mounted(){

  }
}

</script>
